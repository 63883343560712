import Vue from 'vue'
import App from './App.vue'
import api from "./api/index";
import dataV from '@jiaminghi/data-view'

Vue.config.productionTip = false
Vue.use(dataV)
Vue.prototype.$axios = api;

new Vue({
  render: h => h(App),
}).$mount('#app')
