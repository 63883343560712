<template>
  <div class="hello">
    <div>
      <!--      <h1 style="font-size: 5rem;display: flex;width: 100%;position: relative;text-align: center">-->
      <!--        <dv-decoration-3 style="width:250px;height:30px;position: absolute;left: 5rem;top: 1rem"/>-->
      <!--        <div style="width: 100%">实时大数据看板</div>-->
      <!--        <dv-decoration-3 style="width:250px;height:30px;position: absolute;right: 5rem;top: 1rem"/>-->
      <!--      </h1>-->
      <div class="flop">
        <dv-decoration-3 style="width:250px;height:30px;position: absolute;left: 5rem;top: 1rem"/>
        <span style="margin-right: 25px">累计结算</span>
        <span class="flop_number" v-for="(item,index) in formatter(config.num)" :key="index"
              :class="{flop_border: item != ',' && item != '.', flop_bottom: item == ',' || item == '.'}">{{
            item
          }}</span>
        <span style="margin-left: 25px">元</span>
        <dv-decoration-3 style="width:250px;height:30px;position: absolute;right: 5rem;top: 1rem"/>
      </div>
      <div class="flop_issue">
        <span style="margin-right: 45px">今日实时发放 ￥ {{ formatterNum(config.real) }}</span>
        <span>昨日发放 ￥ {{ formatterNum(config.yesterday) }}</span>
        <span style="margin-left: 45px">环比增长 {{ config.sequential }} %</span>
      </div>
      <div class="chart">
        <div class="chart_left">
          <div class="chart_left_top">
            <dv-border-box-7>
              <div class="chart_left_top_tit">人力统计</div>
              <div class="chart_left_top_cen">累积签约 <span>{{configren.total}}</span> <span>人</span></div>
              <div class="chart_left_top_bot">
                <span class="chart_left_top_bot_left">昨日新增 <span>{{configren.yesterday}}</span> 人</span>
                <span class="chart_left_top_bot_right">今日新增 <span>{{configren.real}}</span> 人</span>
              </div>
            </dv-border-box-7>
          </div>
          <div class="chart_left_bottom">
            <dv-border-box-7 :reverse="true">
              <div class="chart_left_top_tit">30天发薪金额</div>
              <dv-scroll-ranking-board :config="moneyList" style="width:100%;height:calc(100% - 3rem)"/>
            </dv-border-box-7>
          </div>
        </div>
        <div class="chart_center">
          <div ref="chart" class="container" style="height: calc(100%);width: 100%"></div>
        </div>
        <div class="chart_left">
          <div class="chart_left_top">
            <dv-border-box-7 style="float: right">
              <div class="chart_left_top_tit">业务统计</div>
              <div class="chart_left_top_cen">累积发包 <span>{{configye.total}}</span> <span>人</span></div>
              <div class="chart_left_top_bot">
                <span class="chart_left_top_bot_left">昨日新增 <span>{{configye.yesterday}}</span> 人</span>
                <span class="chart_left_top_bot_right">今日新增 <span>{{configye.real}}</span> 人</span>
              </div>
            </dv-border-box-7>
          </div>
          <div class="chart_left_bottom">
            <dv-border-box-7 :reverse="true" style="float: right">
              <div class="chart_left_top_tit">任务统计</div>
              <dv-charts :option="progresOption" style="width:100%;height:calc(100% + 10rem);margin-top: -8rem"/>
            </dv-border-box-7>
          </div>
        </div>
      </div>
      <div class="chart_bottom">
        <div class="chart_bottom_left">
          <dv-border-box-7>
            <div class="chart_left_top_tit">30天发薪人数</div>
            <dv-scroll-ranking-board :config="numberList" style="width:100%;height:calc(100% - 3rem)"/>
          </dv-border-box-7>
        </div>
        <div class="chart_bottom_center">
          <dv-border-box-1>
            <div class="chart_bottom_center_page">
              <div class="chart_left_top_tit">全国灵工分布TOP 5</div>
              <dv-charts :option="nationalOption" style="width: 100%;height: calc(23vh - 8rem);"/>
            </div>
          </dv-border-box-1>
        </div>
        <div class="chart_bottom_right">
          <dv-border-box-7 style="float: right">
            <div class="chart_left_top_tit">行业分布</div>
            <dv-scroll-ranking-board :config="industryList" style="width:100%;height:calc(100% - 3rem)"/>
          </dv-border-box-7>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import uploadedDataURL from '../assets/js/data-1528971808162-BkOXf61WX.json'

export default {
  name: 'HelloWorld',
  props: {
    msg: String
  },
  data() {
    return {
      config: {
        num: 12345612312.12,
        real: 4367236462323.31,
        yesterday: 653241231231.34,
        sequential: 23
      },
      configren:{
        total:'',
        yesterday:'',
        real:'',
      },
      configye:{
        total:'',
        yesterday:'',
        real:'',
      },
      chartData: {
        points: [
          {
            name: '郑州',
            coordinate: [0.48, 0.35],
            icon: {
              src: '/img/flylineChart/mapCenterPoint.png',
              width: 30,
              height: 30
            },
            text: {
              color: '#fb7293'
            }
          },
          {
            name: '新乡',
            coordinate: [0.52, 0.23]
          },
          {
            name: '焦作',
            coordinate: [0.43, 0.29]
          },
          {
            name: '开封',
            coordinate: [0.59, 0.35]
          },
          {
            name: '许昌',
            coordinate: [0.53, 0.47]
          },
          {
            name: '平顶山',
            coordinate: [0.45, 0.54]
          },
          {
            name: '洛阳',
            coordinate: [0.36, 0.38]
          },
          {
            name: '周口',
            coordinate: [0.62, 0.55]
          },
          {
            name: '漯河',
            coordinate: [0.56, 0.56]
          },
          {
            name: '南阳',
            coordinate: [0.37, 0.66]
          },
          {
            name: '信阳',
            coordinate: [0.55, 0.81]
          },
          {
            name: '驻马店',
            coordinate: [0.55, 0.67]
          },
          {
            name: '济源',
            coordinate: [0.37, 0.29]
          },
          {
            name: '三门峡',
            coordinate: [0.20, 0.36]
          },
          {
            name: '商丘',
            coordinate: [0.76, 0.41]
          },
          {
            name: '鹤壁',
            coordinate: [0.59, 0.18]
          },
          {
            name: '濮阳',
            coordinate: [0.68, 0.17]
          },
          {
            name: '安阳',
            coordinate: [0.59, 0.10]
          }
        ],
        lines: [
          {
            source: '新乡',
            target: '郑州'
          },
          {
            source: '焦作',
            target: '郑州'
          },
          {
            source: '开封',
            target: '郑州'
          },
          {
            source: '许昌',
            target: '郑州'
          },
          {
            source: '平顶山',
            target: '郑州'
          },
          {
            source: '洛阳',
            target: '郑州'
          },
          {
            source: '周口',
            target: '郑州'
          },
          {
            source: '漯河',
            target: '郑州'
          },
          {
            source: '南阳',
            target: '郑州'
          },
          {
            source: '信阳',
            target: '郑州'
          },
          {
            source: '驻马店',
            target: '郑州'
          },
          {
            source: '济源',
            target: '郑州'
          },
          {
            source: '三门峡',
            target: '郑州'
          },
          {
            source: '商丘',
            target: '郑州'
          },
          {
            source: '鹤壁',
            target: '郑州'
          },
          {
            source: '濮阳',
            target: '郑州'
          },
          {
            source: '安阳',
            target: '郑州'
          }
        ],
        icon: {
          show: false,
        },
        text: {
          show: true,
        },
        bgImgSrc: require('./../assets/ditu.png')
      },
      moneyList: {},
      numberList: {},
      industryList: {},
      progresOption: {},
      nationalOption: {},
      points: [
          {value: [117.3062, 30.9208], itemStyle: {color: '#4ab2e5'}} // 安徽
        , {value: [127.9688, 47.368],  itemStyle: {color: '#4fb6d2'}} // 黑龙江
        , {value: [110.3467, 41.4899], itemStyle: {color: '#52b9c7'}} // 内蒙古
        , {value: [125.8154, 44.2584], itemStyle: {color: '#5abead'}} // 吉林
        , {value: [123.1238, 42.1216], itemStyle: {color: '#f56321'}} // 辽林
        , {value: [115.4995, 39.1006], itemStyle: {color: '#f56f1c'}} // 河北
        , {value: [113.4995, 33.1006], itemStyle: {color: '#f56f1c'}} // 河南
        , {value: [119.2995, 34.1006], itemStyle: {color: '#f56f1c'}} // 江苏
        , {value: [118.1219, 35.6189], itemStyle: {color: '#f58414'}} // 山东
        , {value: [112.3352, 36.9413], itemStyle: {color: '#f58f0e'}} // 山西
        , {value: [103.5901, 36.3043], itemStyle: {color: '#e7ab0b'}} // 甘肃
        , {value: [103.5526, 31.7617], itemStyle: {color: '#c1bb1f'}} // 四川
        , {value: [111.5823, 28.2568], itemStyle: {color: '#a6c62c'}} // 湖南
        , {value: [116.1823, 28.2568], itemStyle: {color: '#a6c62c'}} // 江西
        , {value: [117.1823, 25.2568], itemStyle: {color: '#a6c62c'}} // 福建
        , {value: [111.5823, 31.2568], itemStyle: {color: '#a6c62c'}} // 湖北
        , {value: [102.9199, 24.46639], itemStyle: {color: '#96cc34'}} // 云南
        , {value: [108.9199, 24.46639], itemStyle: {color: '#96cc34'}} // 广西
        , {value: [121.8551, 31.6539], itemStyle: {color: '#f34e2b'}} // 上海
        , {value: [109.3586, 18.8775], itemStyle: {color: '#dfae10'}} // 宁夏
        , {value: [113.4543, 23.9222]}
      ],
      index: -1,
      websock: null,
      dateName: '',
    }
  },
  created() {
    this.initWebSocket();
    this.showAmt()
    this.showPerson()
    this.showAmtCount()
    this.showPersonCount()
    this.showShowSignCount()
    this.ShowTaskCount()
    this.ShowRegion()
    this.UserMcc1List()
  },
  mounted() {
    this.myCharts()
  },
  destroyed() {
    this.websock.close() //离开路由之后断开websocket连接
  },
  methods: {
    // webscokte
    initWebSocket(){ //初始化weosocket
      const wsuri = "ws://8.129.26.240:27772/websocket/001";
      // const wsuri = "ws://192.168.1.132:27772/websocket/001";
      this.websock = new WebSocket(wsuri);
      this.websock.onmessage = this.websocketonmessage;
      this.websock.onopen = this.websocketonopen;
      this.websock.onerror = this.websocketonerror;
      this.websock.onclose = this.websocketclose;
      this.websock.onclose = this.websocketclose;
    },
    websocketonopen(){ //连接建立之后执行send方法发送数据
      // let actions = {"test":"12345"};
      this.websocketsend();
    },
    websocketonerror(){//连接建立失败重连
      setTimeout(()=>{
        this.initWebSocket()
      },3000)
    },
    websocketonmessage(res){ //数据接收
      if (res) {
        if (res.data == '连接成功') {
          console.log(res.data)
        } else {
          let resData = JSON.parse(res.data);
          console.log(resData)
          if (this.config.num != resData.data.count) {
            this.config.num = (resData.data.count + 10000000000)
          }
          this.dateName = new Date()
          console.log(this.formatDateTime(this.dateName,'1'))
          console.log(this.formatDateTime(this.dateName,'2'))
          if (this.formatDateTime(this.dateName,'1') == '09' || this.formatDateTime(this.dateName,'1') == '14') {
            if (this.formatDateTime(this.dateName,'2') == '00' || this.formatDateTime(this.dateName,'2') == '01' || this.formatDateTime(this.dateName,'2') == '02') {
              this.showAmt()
              this.showPerson()
              this.showAmtCount()
              this.showPersonCount()
              this.showShowSignCount()
              this.ShowTaskCount()
              this.ShowRegion()
              this.UserMcc1List()
            }
          }
        }
      }
    },
    websocketsend(){//数据发送
      this.websock.send("");
    },
    websocketclose(e){  //关闭
      console.log('断开连接',e);
      // setTimeout(()=>{
      //   this.initWebSocket()
      // },3000)
    },
    // 业务统计
    showShowSignCount(){
      this.$axios.home.getShowSignCount().then(res=>{
        if (res.code == 200) {
          this.configye.total = res.data.count
          this.configye.real = res.data.today
          this.configye.yesterday = res.data.yesterday
        }
      })
    },
    // 行业分布
    UserMcc1List(){
      this.$axios.home.getUserMcc1List().then(res=>{
        if (res.code == 200) {
          let name = []
          let value = []
          this.industryList = {
            data: [],
            unit: '户',
            rowNum: 5,
            carousel: 'single',
            waitTime: 1000
          }
          for (let key in res.data) {
            this.industryList.data.push({
              name: key,
              value: res.data[key],
            })
          }

        }
      })
    },
    Desensitization(str,beginLen,endLen) {
      let len = str.length;
      let firstStr = str.substr(0,beginLen);
      let lastStr = str.substr(endLen);
      let middleStr = str.substring(beginLen, len-Math.abs(endLen)).replace(/[\s\S]/ig, '*');
      console.log(middleStr)
      let tempStr = '*****'
      if (middleStr != '') {
        tempStr = firstStr + middleStr + lastStr;
      }
      return tempStr;
    },
    // 全国灵工分布
    ShowRegion(){
      this.$axios.home.getShowRegion().then(res=>{
        if (res.code == 200) {
          let name = []
          let value = []
          for (let key in res.data) {
            name.push(key)
            value.push(res.data[key])
          }
          this.nationalOption = {
            xAxis: {
              data: name.slice(0,5),
              axisLine: {
                style: {
                  stroke: '#fff'
                }
              },
              axisTick: {
                style: {
                  stroke: '#fff',
                }
              },
              axisLabel: {
                style: {
                  fill: '#fff',
                  fontSize: 14,
                }
              }
            },
            yAxis: {
              name: '灵工人数',
              data: 'value',
              axisLine: {
                style: {
                  stroke: '#fff'
                }
              },
              axisTick: {
                style: {
                  stroke: '#fff',
                }
              },
              axisLabel: {
                style: {
                  fill: '#fff',
                  fontSize: 12,
                }
              }
            },
            grid: {
              left: '8%',
              right: '0',
              bottom: '16%',
              top: '5%',
              containLabel: true
            },
            series: [
              {
                data: value.slice(0,5),
                type: 'bar',
                barWidth: 40,
                label: {
                  show: true,
                  position: 'center',
                  offset: [0, 0],
                  style: {
                    fill: '#fff'
                  }
                },
              },
            ]
          }
        }
      })
    },
    // 任务统计
    ShowTaskCount(){
      this.$axios.home.getShowTaskCount().then(res=>{
        if (res.code == 200) {
          this.progresOption = {
            series: [
              {
                type: 'pie',
                data: [
                  {name: '已完成 ' + res.data.haveCount, value: res.data.haveCount},
                  {name: '进行中 ' + res.data.finishCount, value: res.data.finishCount},
                  // {name: '任务总数 ' + res.data.count, value: res.data.count},
                ],
                insideLabel: {
                  show: true
                },
              }
            ]
          }
        }
      })
    },
    // 人力统计
    showPersonCount(){
      this.$axios.home.getShowPersonCount().then(res=>{
        if (res.code == 200) {
          this.configren.total = (res.data.count + 1000000)
          this.configren.real = res.data.today
          this.configren.yesterday = res.data.yesterday
        }
      })
    },
    // 30天发信金额
    showAmt(){
      this.$axios.home.getShowAmt().then(res=>{
        let formData = []
        if (res.code == 200) {
          res.data.forEach( item => {
            formData.push(
                {
                  name: this.Desensitization(item.mer_name,2,-3),
                  value: item.amt
                }
            )
          })
          this.moneyList = {
            data: formData,
            carousel: 'single',
            waitTime: 1000
          }
        }
      })
    },
    // 30天发薪人数
    showPerson(){
      this.$axios.home.getShowPerson().then(res=>{
        let formData = []
        if (res.code == 200) {
          res.data.forEach( item => {
            formData.push(
                {
                  name: this.Desensitization(item.mer_name,2,-3),
                  value: item.counts
                }
            )
          })
          this.numberList = {
            data: formData,
            rowNum: 5,
            carousel: 'single',
            waitTime: 1000
          }
        }
      })
    },
    // 累计结算
    showAmtCount(){
      this.$axios.home.getShowAmtCount().then(res=>{
        if (res.code == 200) {
          this.config.num = (res.data.count + 10000000000)
          this.config.real = res.data.today
          this.config.yesterday = res.data.yesterday
          this.config.sequential = this.GetPercent(res.data.today || 0,res.data.yesterday || 0)
        }
      })
    },
    GetPercent(num, total) {
      /// <summary>
      /// 求百分比
      /// </summary>
      /// <param name="num">当前数</param>
      /// <param name="total">总数</param>
      num = parseFloat(num);
      total = parseFloat(total);
      if (isNaN(num) || isNaN(total)) {
        return "-";
      }
      if (num > total) {
        if (total == 0) {
          return 100
        } else {
          if (num == 0) {
            return -100
          } else {
            return (Math.round(num / total * 10000) / 100.00);
          }
        }
      } else {
        if (num == 0 && total == 0) {
          return 0
        } else {
          return '- ' + (Math.round(num / total * 10000) / 100.00);
        }
      }
    },
    formatDateTime(inputTime,type) {
      let date = new Date(inputTime);
      let y = date.getFullYear();
      let m = date.getMonth() + 1;
      m = m < 10 ? ('0' + m) : m;
      let d = date.getDate();
      d = d < 10 ? ('0' + d) : d;
      let h = date.getHours();
      h = h < 10 ? ('0' + h) : h;
      let minute = date.getMinutes();
      let second = date.getSeconds();
      minute = minute < 10 ? ('0' + minute) : minute;
      second = second < 10 ? ('0' + second) : second;
      if (type === '1') {
        return h
      } else if (type === '2') {
        return minute
      } else {
        return y + '-' + m + '-' + d+' '+h+':'+minute+':'+second;
      }
    },
    myCharts() {
      let myChart = echarts.init(this.$refs.chart);
      let geoJson = uploadedDataURL
      echarts.registerMap('china', geoJson);
      let option = {
        geo: {
          map: 'china',
          aspectScale: 0.75, //长宽比
          zoom: 1.1,
          top: '5%',
          bottom: "20%",
          roam: false,
          itemStyle: {
            normal: {
              areaColor: {
                type: 'radial',
                x: 0.5,
                y: 0.5,
                r: 0.8,
                colorStops: [{
                  offset: 0,
                  color: '#09132c' // 0% 处的颜色
                }, {
                  offset: 1,
                  color: '#274d68'  // 100% 处的颜色
                }],
                globalCoord: true // 缺省为 false
              },
              shadowColor: 'rgb(58,115,192)',
              shadowOffsetX: 10,
              shadowOffsetY: 11
            },
            emphasis: {
              areaColor: '#2AB8FF',
              borderWidth: 0,
              color: 'green',
              label: {
                show: false
              }
            }
          },
          regions: [{
            name: '南海诸岛',
            itemStyle: {
              areaColor: 'rgba(0, 10, 52, 1)',

              borderColor: 'rgba(0, 10, 52, 1)',
              normal: {
                opacity: 0,
                label: {
                  show: false,
                  color: "#009cc9",
                }
              }
            },


          }],
        },
        series: [{
          top: '5%',
          bottom: "20%",
          type: 'map',
          roam: false,
          label: {
            normal: {
              show: true,
              textStyle: {
                color: '#1DE9B6'
              }
            },
            emphasis: {
              textStyle: {
                color: 'rgb(183,185,14)'
              }
            }
          },

          itemStyle: {
            normal: {
              borderColor: 'rgb(147, 235, 248)',
              borderWidth: 1,
              areaColor: {
                type: 'radial',
                x: 0.5,
                y: 0.5,
                r: 0.8,
                colorStops: [{
                  offset: 0,
                  color: '#09132c' // 0% 处的颜色
                }, {
                  offset: 1,
                  color: '#274d68'  // 100% 处的颜色
                }],
                globalCoord: true // 缺省为 false
              },
            },
            emphasis: {
              areaColor: 'rgb(46,229,206)',
              //    shadowColor: 'rgb(12,25,50)',
              borderWidth: 0.1
            }
          },
          zoom: 1.1,
          //     roam: false,
          map: 'china' //使用
          // data: this.difficultData //热力图数据   不同区域 不同的底色
        }, {
          type: 'effectScatter',
          coordinateSystem: 'geo',
          showEffectOn: 'render',
          zlevel: 1,
          rippleEffect: {
            period: 15,
            scale: 4,
            brushType: 'fill'
          },
          hoverAnimation: true,
          label: {
            normal: {
              formatter: '{b}',
              position: 'right',
              offset: [15, 0],
              color: '#1DE9B6',
              show: true
            },
          },
          itemStyle: {
            normal: {
              color: '#1DE9B6',
              shadowBlur: 10,
              shadowColor: '#333'
            }
          },
          symbolSize: 12,
          data: this.points
        }, //地图线的动画效果
          {
            type: 'lines',
            zlevel: 2,
            effect: {
              show: true,
              period: 4, //箭头指向速度，值越小速度越快
              trailLength: 0.4, //特效尾迹长度[0,1]值越大，尾迹越长重
              symbol: 'arrow', //箭头图标
              symbolSize: 7, //图标大小
            },
            lineStyle: {
              normal: {
                color: '#1DE9B6',
                width: 1, //线条宽度
                opacity: 0.1, //尾迹线条透明度
                curveness: .3 //尾迹线条曲直度
              }
            },
            data: [
                {coords: [[117.3062, 30.9208], [113.4543, 23.9222]], lineStyle: {color: '#4ab2e5'}}
              , {coords: [[127.9688, 47.368],  [113.4543, 23.9222]], lineStyle: {color: '#4fb6d2'}}
              , {coords: [[110.3467, 41.4899], [113.4543, 23.9222]], lineStyle: {color: '#52b9c7'}}
              , {coords: [[125.8154, 44.2584], [113.4543, 23.9222]], lineStyle: {color: '#5abead'}}
              , {coords: [[123.1238, 42.1216], [113.4543, 23.9222]], lineStyle: {color: '#f34e2b'}}
              , {coords: [[115.4995, 39.1006], [113.4543, 23.9222]], lineStyle: {color: '#f56321'}}
              , {coords: [[113.4995, 33.1006], [113.4543, 23.9222]], lineStyle: {color: '#f56f1c'}}
              , {coords: [[119.2995, 34.1006], [113.4543, 23.9222]], lineStyle: {color: '#f58414'}}
              , {coords: [[118.1219, 35.6189], [113.4543, 23.9222]], lineStyle: {color: '#f58f0e'}}
              , {coords: [[112.3352, 36.9413], [113.4543, 23.9222]], lineStyle: {color: '#f5a305'}}
              , {coords: [[103.5901, 36.3043], [113.4543, 23.9222]], lineStyle: {color: '#e7ab0b'}}
              , {coords: [[103.5526, 31.7617], [113.4543, 23.9222]], lineStyle: {color: '#dfae10'}}
              , {coords: [[111.5823, 28.2568], [113.4543, 23.9222]], lineStyle: {color: '#d5b314'}}
              , {coords: [[116.1823, 28.2568], [113.4543, 23.9222]], lineStyle: {color: '#c1bb1f'}}
              , {coords: [[117.1823, 25.2568], [113.4543, 23.9222]], lineStyle: {color: '#b9be23'}}
              , {coords: [[111.5823, 31.2568], [113.4543, 23.9222]], lineStyle: {color: '#a6c62c'}}
              , {coords: [[102.9199, 24.46639],[113.4543, 23.9222]], lineStyle: {color: '#96cc34'}}
              , {coords: [[108.9199, 24.46639],[113.4543, 23.9222]], lineStyle: {color: '#96cc34'}}
              , {coords: [[121.8551, 31.6539], [113.4543, 23.9222]], lineStyle: {color: '#96cc34'}}
              , {coords: [[109.3586, 18.8775], [113.4543, 23.9222]], lineStyle: {color: '#96cc34'}}
              , {coords: [[113.4543, 23.9222], [113.4543, 23.9222]], lineStyle: {color: '#96cc34'}}
            ]
          }

        ]
      };
      myChart.setOption(option, true);
    },
    formatter(number) {
      let data = ''
      if (number) {
        try {
          if (number * 1) {
            (number + '').replace(/,/g, '')
            data = (number * 1).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,')
          } else {
            data = '0'
          }
        } catch (e) {
          console.log(e)
        }
      } else {
        data = '0'
      }
      const numbers = data.toString().split('')
      return numbers;
    },
    formatterNum(number) {
      let data = ''
      if (number) {
        try {
          if (number * 1) {
            (number + '').replace(/,/g, '')
            data = (number * 1).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,')
          } else {
            data = '0'
          }
        } catch (e) {
          console.log(e)
        }
      } else {
        data = '0'
      }
      return data;
    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
h3 {
  margin: 4rem 0 0;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  display: inline-block;
  margin: 0 1rem;
}

a {
  color: #42b983;
}

.hello {
  background: url("./../assets/bg.jpg") no-repeat;
  background-size: 100% 100%;
  color: white;
  padding-top: 2rem;
  padding-left: 2.5rem;
  padding-right: 2.5rem;
  height: calc(100vh - 2rem);
}

.flop {
  text-align: center;
  position: relative;
  margin-top: 3rem;

  span {
    font-size: 2.4rem;
    font-weight: 600;
  }

  .flop_number {
    font-size: 3.2rem !important;
    margin: 1rem;
  }

  .flop_border {
    border: .1rem solid white;
    padding: 1.5rem;
  }

  .flop_bottom {
    position: relative;
    bottom: -1rem;
  }
}

.flop_issue {
  margin-top: 3.5rem;
  background: rgba(255, 255, 255, 0.08);
  padding: 1rem;
  font-size: 1.6rem;
}

.chart {
  display: flex;
  height: calc(82vh - 20rem);


  .chart_center {
    height: calc(100vh - 25.1rem);
    width: 55%;
    padding-top: 0rem;
  }

  .chart_right {
    width: 30%;
    height: 100%;
  }

  .chart_left {
    width: 30%;
    height: calc(100% - 2.5rem);
    margin-top: 2.5rem;

    .chart_left_top {
      height: 38%;
      overflow: hidden;

      .dv-border-box-7 {
        padding: 2.5rem;
        width: calc(100% - 7rem);
        height: calc(100% - 5rem);
      }

      .chart_left_top_tit {
        text-align: left;
        font-weight: 600;
        font-size: 1.8rem;
      }

      .chart_left_top_cen {
        font-size: 1.2rem;

        span {
          font-size: 2.4rem;
          font-weight: 600;
          padding-left: 2.5rem;
        }

        padding-bottom: 1.5rem;
        padding-top: 1.5rem;
        border-bottom: .1rem solid rgba(255, 255, 255, 0.4);
      }

      .chart_left_top_bot {
        font-size: 1.6rem;
        padding: 2rem 0;

        .chart_left_top_bot_left {
          width: calc(50% - .1rem);
          border-right: .1rem solid rgba(255, 255, 255, 0.4);
          text-align: center;
          display: inline-block;
        }

        .chart_left_top_bot_right {
          width: 50%;
          text-align: center;
          display: inline-block;
        }
      }
    }

    .chart_left_bottom {
      height: calc(68% - 8rem);
      margin-top: 1rem;
      clear: both;
      text-align: left;

      .dv-border-box-7 {
        padding: 2.5rem;
        width: calc(100% - 7rem);
        height: calc(100% - 5rem);
      }

      .chart_left_top_tit {
        text-align: left;
        font-weight: 600;
        margin-bottom: 1.5rem;
        font-size: 1.8rem;
      }
    }
  }
}

.chart_bottom {
  display: flex;
  margin-top: -2.5rem;

  .chart_bottom_left {
    width: 30%;
    height: calc(23vh - 5rem);
    text-align: left;

    .dv-border-box-7 {
      padding: 2.5rem;
      width: calc(100% - 7rem);
    }

    .chart_left_top_tit {
      text-align: left;
      font-weight: 600;
      margin-bottom: 1.5rem;
      font-size: 1.8rem;
    }
  }

  .chart_bottom_center {
    width: 55%;
    height: 23vh;

    .chart_bottom_center_page {
      width: calc(100% - 5rem);
      padding: 2.5rem;
    }

    .chart_left_top_tit {
      text-align: left;
      font-weight: 600;
      margin-bottom: 1.5rem;
      font-size: 1.8rem;
    }
  }

  .chart_bottom_right {
    width: 30%;
    height: calc(23vh - 5rem);
    text-align: left;

    .dv-border-box-7 {
      padding: 2.5rem;
      width: calc(100% - 7rem);
    }

    .chart_left_top_tit {
      text-align: left;
      font-weight: 600;
      margin-bottom: 1.5rem;
      font-size: 1.8rem;
    }
  }

}

/deep/ .dv-active-ring-chart .active-ring-info {
  align-items: flex-start;
  left: -2rem;
}
</style>
