import request from "@/utils/axios";
//获取登录用户信息
export function getUserInfo(params) {
    return request({
        url: params,
        method: "GET",
    });
}
export function getShowAmt(params) {
    return request({
        url: '/operation/view/showAmt',
        method: "GET",
    });
}
export function getShowPerson(params) {
    return request({
        url: '/operation/view/showPerson',
        method: "GET",
    });
}
export function getShowAmtCount(params) {
    return request({
        url: '/operation/view/showAmtCount',
        method: "GET",
    });
}
export function getShowPersonCount(params) {
    return request({
        url: '/operation/view/showPersonCount',
        method: "GET",
    });
}
export function getShowSignCount(params) {
    return request({
        url: '/operation/view/showSignCount',
        method: "GET",
    });
}
export function getShowTaskCount(params) {
    return request({
        url: '/operation/view/showTaskCount',
        method: "GET",
    });
}
export function getShowRegion(params) {
    return request({
        url: '/operation/view/showRegion',
        method: "GET",
    });
}
export function getUserMcc1List(params) {
    return request({
        url: '/operation/view/userMcc1List',
        method: "GET",
    });
}

