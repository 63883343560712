import axios from 'axios'
import {
    Message,
    MessageBox
} from 'element-ui'
import {
    getToken,
    removeToken
} from '@/utils/auth'
import {
    Loading
} from 'element-ui'
const CancelToken = axios.CancelToken;
const source = CancelToken.source();
// import _ from 'lodash'

let loading //定义loading变量
let needLoadingRequestCount = 0

function startLoading() {
    //使用Element loading-start 方法
    loading = Loading.service({
        lock: true,
        text: '加载中……',
        background: 'rgba(0, 0, 0, 0.7)',
    })
}

function endLoading() {
    //使用Element loading-close 方法
    loading.close()
}
//那么 showFullScreenLoading() tryHideFullScreenLoading() 要干的事儿就是将同一时刻的请求合并。
//声明一个变量 needLoadingRequestCount，每次调用showFullScreenLoading方法 needLoadingRequestCount + 1。
//调用tryHideFullScreenLoading()方法，needLoadingRequestCount - 1。needLoadingRequestCount为 0 时，结束 loading。
export function showFullScreenLoading() {
    if (needLoadingRequestCount === 0) {
        startLoading()
    }
    needLoadingRequestCount++
}

export function tryHideFullScreenLoading() {
    if (needLoadingRequestCount <= 0) return
    needLoadingRequestCount--
    if (needLoadingRequestCount === 0) {
        endLoading()
    }
}
let config = {
    baseURL: process.env.VUE_APP_URL, // api的base_url
    timeout: 240000, // Timeout
    showLoading: false, //是否需要loading效果,如果不需要,则在请求时的第三个参数中传{showLoading:false}
}
// 请求时的第三个参数中传{showLoading:false}
// export function page(params) {
//   return request({
//     url: '/api/car/carTypeController/page',
//     method: 'post',
//     showLoading: true,
//     data: params,
//   })
// }
// 创建axios实例
let service = axios.create(config)
// request拦截器
service.interceptors.request.use(

    (config) => {
        if (getToken('Token')) {
            config.headers = {
                Authorization: getToken('Token'), //携带权限参数
            }
        }
        if (config.showLoading) {
            showFullScreenLoading()
        }
        return config
    },
    (error) => {
        console.log(error, 'errorerrorerror')
        Promise.reject(error)
    }
)

// respone拦截器
service.interceptors.response.use(
    (response) => {
        /**
         * code:200,接口正常返回;
         */
        if (response.config.showLoading) {
            tryHideFullScreenLoading()
        }
        // 处理blob类型
        if (response.config.responseType === 'blob') {
            return response
        }
        const res = response.data
        if (res.code !== 200) {
            Message({
                message: res.message,
                type: 'error',
                duration: 5 * 1000,
            })
            // 根据服务端约定的状态码：5001:非法的token; 5002:其他客户端登录了; 5004:Token 过期了;
            if (
                res.code === 5001 ||
                res.code === 5002 ||
                res.code === 5004 ||
                res.code === 401 ||
                res.code === 403
            ) {
                MessageBox.confirm(
                    '你已被登出，可以取消继续留在该页面，或者重新登录',
                    '确定登出', {
                    confirmButtonText: '重新登录',
                    cancelButtonText: '取消',
                    type: 'warning',
                }
                ).then(() => {
                    // store.dispatch('LogOut').then(() => {
                    //     source.cancel();
                    //     setTimeout(() => {
                    //         location.reload() //为了重新实例化vue-router对象 避免bug
                    //     }, 1800)
                    // })
                })
            }
            return Promise.reject(res)
        } else {
            // res.code === 200,正常返回数据
            return response.data
        }
    },
    (error) => {
        if (error.response && error.response.data && error.response.data.message) {
            Message({
                message: error.response.data.message,
                type: 'error',
                duration: 5 * 1000,
            })
        } else {
            Message({
                message: error.message,
                type: 'error',
                duration: 5 * 1000,
            })
        }
        return Promise.reject(error)
    }
)

export default service
